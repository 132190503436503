import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "FCC Certification",
  "description": "FCC (Federal Communications Commission) is a mandatory certification for electronic products to enter the US market. Learn more about the certification, its applications, and how to apply for it. ",
  "author": "Happy Fei",
  "categories": ["certification"],
  "date": "2023-02-05T16:00:00.000Z",
  "featured": false,
  "tags": ["qc", "certification"],
  "relatedArticles": ["https://knowledgebase.nexpcb.com/articles/emc-and-emi-compliance/"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.fcc.gov/"
      }}>{`FCC (Federal Communications Commission)`}</a>{` is a mandatory certification for electronic products to enter the US market. The certification is applicable in more than 50 states across the US, also in Canada, and some countries in Central and South America.`}</p>
    <p><a parentName="p" {...{
        "href": "https://knowledgebase.nexpcb.com/articles/emc-and-emi-compliance/"
      }}>{`EMC products`}</a>{`, with 9kHz to 3000GHz operation, need to have FCC certification. The certification standard controls the radio transmission, communication principles, and many other operating features of the electronics. Radio interference limits and measurement methods, as well as the certification system and organizational management system, are also regulated by the FCC.`}</p>
    <p>{`The common products regulated by the FCC are personal computers, CD players, printers, radios, fax machines, video game consoles, electronic toys, televisions, and microwave ovens.`}</p>
    <p>{`These products are further classified into 2 categories,`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Class A,`}</strong>{` which are the electronics used for commercial or industrial use, and`}</li>
      <li parentName="ul"><strong parentName="li">{`Class B,`}</strong>{` which are electronics meant for household use.`}</li>
    </ul>
    <p>{`The FCC has stricter requirements for `}<strong parentName="p">{`Class B`}</strong>{` products, with lower limit values than `}<strong parentName="p">{`Class A`}</strong>{`. For most electronic and electrical products, the main standards are `}<em parentName="p">{`FCC Part 15`}</em>{` and `}<em parentName="p">{`FCC Part 18`}</em>{`.`}</p>
    <h2 {...{
      "id": "fcc-options"
    }}>{`FCC Options`}</h2>
    <p>{`The FCC has three options for equipment approval: `}<strong parentName="p">{`Verification, Declaration of Conformity (DoC),`}</strong>{` and `}<strong parentName="p">{`Certification`}</strong>{`. Each of these certification types is used for different applications and has different testing and declaration. Hence, they all need specific testing laboratories to obtain FCC accreditation.`}</p>
    <h3 {...{
      "id": "verification"
    }}>{`Verification`}</h3>
    <p>{`During the process, the manufacturer or importer ensures that its products undergo the necessary testing to confirm that the product meets the relevant technical standards and has completed the FCC certification process, with a legit test report.`}</p>
    <p>{`Electronic products suitable for the ‘Verification’ type include`}</p>
    <ul>
      <li parentName="ul">{`Commercial computers`}</li>
      <li parentName="ul">{`TV and FM receivers`}</li>
      <li parentName="ul">{`Industrial, scientific, and medical equipment for non-mass consumer use under FCC Rule `}<em parentName="li">{`Part 18.`}</em></li>
    </ul>
    <h3 {...{
      "id": "declaration-of-conformity-doc"
    }}>{`Declaration of Conformity (DoC)`}</h3>
    <p>{`The manufacturer or importer of the product tests the product at a qualified testing agency designated by the FCC to ensure that the equipment complies with the relevant technical standards and retains the test report. The certification body must be authorized by `}<strong parentName="p">{`NVLAP/A2LA`}</strong>{`.`}</p>
    <p>{`Electronic applications suitable for the ‘DoC’ certification include:`}</p>
    <ul>
      <li parentName="ul">{`Household computers and their peripherals`}</li>
      <li parentName="ul">{`Broadcast receivers`}</li>
      <li parentName="ul">{`Super-regenerative receivers`}</li>
      <li parentName="ul">{`other receivers listed on the FCC Rule `}<em parentName="li">{`Part 15`}</em>{`,`}</li>
      <li parentName="ul">{`TV interface equipment`}</li>
      <li parentName="ul">{`Cable system terminal equipment`}</li>
      <li parentName="ul">{`Industrial and scientific research equipment for consumers listed in `}<em parentName="li">{`Part 18`}</em></li>
      <li parentName="ul">{`Medical equipment.`}</li>
    </ul>
    <h3 {...{
      "id": "certification"
    }}>{`Certification`}</h3>
    <p>{`The certification process gives an ID to the certified product. Certification is the process by which FCC signs equipment approval based on application and test data. FCC reviews the samples (or photos) and test data submitted by the applicant and authorizes the device with an FCCID number if it meets the requirements of the FCC rules. All devices which are FCC-Certified would have an FCCID mark on them.`}</p>
    <p>{`Electronic applications suitable for the ‘Certification’ includes:`}</p>
    <ul>
      <li parentName="ul">{`Low-power transmitters such as cordless phones, remote controls for automatic doors, radio-controlled toys, and security alarm systems.`}</li>
      <li parentName="ul"><em parentName="li">{`Part 15`}</em>{` devices that intentionally emit radio frequency energy.`}</li>
      <li parentName="ul"><em parentName="li">{`Part 18`}</em>{` industrial, scientific research, and medical equipment.`}</li>
      <li parentName="ul">{`automatic frequency conversion receivers and super-regenerative receivers.`}</li>
      <li parentName="ul">{`TV interface equipment`}</li>
      <li parentName="ul">{`Personal computers and their peripherals.`}</li>
    </ul>
    <h2 {...{
      "id": "fcc-services-for-different-devices"
    }}>{`FCC Services for Different Devices`}</h2>
    <p>{`The FCC deals with numerous electronic products. These products are further divided into a range of categories, which fit different rules and require different services and testing.`}</p>
    <h3 {...{
      "id": "product-categories"
    }}>{`Product Categories`}</h3>
    <p>{`Most of the electronic devices we found fall into the `}<em parentName="p">{`Part 15`}</em>{` and `}<em parentName="p">{`Part 18`}</em>{` categories. However, there are still numerous categories as stated below:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`FCC PART15C/E/F`}</strong>{` Intentional Radiation Device Test: Provisions are made for intentional, unintentional, or transient emission devices that do not require individual licenses in use. It includes technical specifications, administrative requirements, and other market access conditions. Products are mainly divided into four categories: unintentional launch equipment, intentional launch equipment, personal communication equipment that does not require a license, and basic information equipment that does not require a license.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART18`}</strong>{` Industrial, scientific and medical equipment: Regulations are made for electromagnetic energy emitted by industrial, scientific, and medical equipment (ISM) operating on a certain frequency spectrum, in order to avoid harmful interference of the above-mentioned equipment to authorized wireless communication services.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART22`}</strong>{` Public mobile communication service`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART24`}</strong>{` Personal Communication Service`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART25`}</strong>{` Satellite communication service`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART27`}</strong>{` Other FCC Wireless Communication Services`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC PART68`}</strong>{` Telecommunication terminal equipment`}</li>
    </ul>
    <h3 {...{
      "id": "fcc-testing-types-and-applications"
    }}>{`FCC Testing Types and Applications`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Power supply FCC certification`}</strong>{`: communication power supply, switching power supply, charger, display power supply, LED power supply, LCD power supply, uninterruptible power supply UPS, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC certification of lamps`}</strong>{`: chandeliers, track lights, garden lights, portable lights, downlights, LED street lights, light strings, table lamps, LED spotlights, LED bulb lights, grille lights, aquarium lights, street lights, LED tube lights, LED lamps, energy-saving lamps, T8 lamps, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC certification for home appliances`}</strong>{`: fans, electric kettles, stereos, TVs, mice, vacuum cleaners, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Electronic FCC certification`}</strong>{`: earplugs, routers, mobile phone batteries, laser pointers, vibrators, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC certification for communication products`}</strong>{`: telephones, wired telephones, and wireless main and auxiliary machines, fax machines, answering machines, modems, data interface cards, and other communication products.`}</li>
      <li parentName="ul"><strong parentName="li">{`FCC certification of wireless products`}</strong>{`: Bluetooth BT products, tablet computers, wireless keyboards, wireless mice, wireless readers, wireless transceivers, wireless walkie-talkies, wireless microphones, remote controls, wireless network devices, wireless image transmission systems, and other low-power wireless products, etc.`}</li>
      <li parentName="ul">{`F`}<strong parentName="li">{`CC certification of wireless communication products:`}</strong>{` 2G mobile phones, 3G mobile phones, 3.5G mobile phones, DECT mobile phones (1.8G, 1.9G frequency), wireless walkie-talkies, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Mechanical FCC certification`}</strong>{`: gasoline engine, electric welding machine, CNC drilling machine, tool grinder, lawn mower, washing equipment, bulldozer, lift, punching machine, dishwasher, water treatment equipment, gasoline hair welding machine, printing machinery, woodworking machinery, rotary Digging rigs, mowing machines, snowplows, excavators, printing machines, printers, cutting machines, road rollers, troweling machines, brush cutters, hair straighteners, food machinery, lawn machines, etc.`}</li>
    </ul>
    <h2 {...{
      "id": "fcc-certification-process"
    }}>{`FCC Certification Process`}</h2>
    <h3 {...{
      "id": "getting-the-fccid"
    }}>{`Getting the FCCID`}</h3>
    <p>{`An FCC Certified product will have a unique FCCID assigned to them. This will prove that the product has passed the FCC standards, and are safe to market within the US and other regions covered by the FCC. All FCC-certified products will have their FCCID marked on them.`}</p>
    <p>{`What's an FCCID?`}</p>
    <blockquote>
      <p parentName="blockquote">{`Products subject to certification are required to be uniquely identified by an identifier known as FCC ID. The FCC ID consist of two parts: (1) grantee code; and (2) product code.`}</p>
      <p parentName="blockquote">{`The grantee code, as specified in Section 2.926, is a three or five character alphanumeric string representing the Grantee / Applicant for certified products.`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`Grantee Codes that begin with an alpha character (A-Z) consist of three characters.`}</li>
        <li parentName="ul">{`Grantee Codes that begin with a number (2-9) consist of five characters.`}</li>
        <li parentName="ul">{`The grantee code does not contain the numbers “one” and/or “zero”. The grantee code is assigned by the Commission permanently to a company for use in the identification of radio frequency equipment authorized under the certification procedure.`}</li>
      </ul>
      <p parentName="blockquote">{`The product codes are uniquely established by the Grantee to identify each product approved by a Telecommunication Certification Body (TCB). The product code can be up to 14 alpha numeric characters. Hyphens (–) separated by an alpha numeric character are permitted; consecutive hyphens (-) are not permitted.`}</p>
    </blockquote>
    <p>{`You can get the Grantee Code from the FCC official website at the cost of USD 35. Once you get your Grantee Code, you can directly use it.`}</p>
    <p>{`The method of applying for a Grantee Code is as follows,`}</p>
    <ol>
      <li parentName="ol">{`First, apply for an FRN`}</li>
      <li parentName="ol">{`Fill in the registration form: based on what you fill in the form, you will get a Grantee Code.`}</li>
      <li parentName="ol">{`Pay for your Grantee Code through the link “`}<a parentName="li" {...{
          "href": "https://apps.fcc.gov/oetcf/eas/forms/Modify159GranteeWelcome.cfm?calledFromFrame=N"
        }}>{`Return to 159 / Pay for a Grantee registration`}</a>{`”`}</li>
      <li parentName="ol">{`Once you’re done with the payment, you’ve finally obtained the Grantee Code.`}</li>
    </ol>
    <p>{`Other than the Grantee Code, you should also apply your product for testing.`}</p>
    <ol>
      <li parentName="ol">{`As an applicant, you should spare some time to bring your product to a `}<strong parentName="li">{`third-party laboratory certified by the FCC to test your products`}</strong>{`. The testing procedure and the cost generated by this lab will refer to the FCC certification standards.`}</li>
      <li parentName="ol">{`Have your partners in the production (manufacturing and testing) prepare some of the `}<strong parentName="li">{`relevant documents`}</strong>{`, including equipment BOM, equipment manual, FCC warnings; equipment operation instructions, circuit schematic diagram, block diagram, FCC LOG location, and other documents of the electronic part of the equipment.`}</li>
      <li parentName="ol">{`Afterward, you'll need to `}<strong parentName="li">{`submit the documents stated above along with the lab test report to the FCC`}</strong>{`. The FCC will assess your documents before finally giving you the FCC ID. The average time taken for the whole process to take place is around `}<strong parentName="li">{`60 days`}</strong>{`.`}</li>
      <li parentName="ol">{`At the end of the process, FCC will send the Original Grant of FCC ID to the applicant. Once you have the certificate, you can already `}<strong parentName="li">{`sell or export the corresponding products.`}</strong></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      